import React from "react";
import TextInput from "../formComponents/TextInput";
import UploadField from "../formComponents/UploadField";
import SelectTheme from "../formComponents/SelectTheme";


const AboutMe = ({editable, theme}) => {

    let styles, imgBackground;

    switch (editable.theme) {
        case 'light':
            styles = {
                backgroundColor: theme.light_background,
                color: theme.dark_font
            }
            imgBackground = {
                boxShadow: `2.5rem 2.5rem 0 ${theme.light_accent}`
            }
        break;
        case 'color':
            styles = {
                backgroundColor: theme.primary,
                color: theme.light_font
            }
            imgBackground = {
                boxShadow: `2.5rem 2.5rem 0 ${theme.light_font}`
            }
        break;
        default: {
            styles = {
                backgroundColor: theme.dark_background,
                color: theme.light_font
            }
            imgBackground = {
                boxShadow: `2.5rem 2.5rem 0 ${theme.dark_accent}`
            }
        }
    }

    const imgStyles = {
        ...imgBackground,
        backgroundImage: `url(${editable.img})`
    }

    return (
         <div
            className='about-me'
            style={styles}
         >
            <div className='about-me__wrapper'>
            <div className='about-me__text-wrapper'>
                <h1 className='about-me__title'>{editable.title}</h1>
                <p className='about-me__description'>{editable.description}</p>
            </div>
            <div className='about-me__img-wrapper'>
                <div className='about-me__img-container' style={imgStyles}>
                </div>
            </div>
            </div>
        </div>
    )
}

const aboutMeSetup = {
    type: 'AboutMe',
    label: '"O mnie"',
    preview: process.env.PUBLIC_URL + '/elements/AboutMe.png',
    editable: {
        'theme': 'light',
        'title': 'O mnie:',
        'img': process.env.PUBLIC_URL + '/preview/ManImage.jpg',
        'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mi diam, fringilla eget orci eu, consequat elementum odio. Fusce eget turpis sed lacus hendrerit imperdiet. Aenean semper nibh sit amet nunc maximus, ac interdum mi porttitor. Morbi a lectus id quam ornare mollis vitae at mauris.',
    }
}

const AboutMeEditPage = () => {

    return (
        <div>
            <SelectTheme />
            <TextInput editableName='title' label='Tytuł'/>
            <TextInput editableName='description' label='Opis'/>
            <UploadField editableName='img' label='Zdjęcie profilowe'/>
        </div>
    );
}

export default AboutMe;
export {aboutMeSetup, AboutMeEditPage}