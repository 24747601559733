import React from "react";
import SelectTheme from "../formComponents/SelectTheme";

const SimpleLine = ({ editable, theme }) => {
  let styles;
  let lineStyles;

  switch (editable.theme) {
    case "light":
      styles = {
        backgroundColor: theme.light_background,
      };
      lineStyles = {
        backgroundColor: theme.light_accent,
      };
      break;
    case "color":
      styles = {
        backgroundColor: theme.primary,
      };
      lineStyles = {
        backgroundColor: theme.light_font,
      };
      break;
    default: {
      styles = {
        backgroundColor: theme.dark_background,
      };
      lineStyles = {
        backgroundColor: theme.dark_accent,
      };
    }
  }

  return (
    <>
      <div className="simple-line" style={styles}>
        <div className="simple-line__line" style={lineStyles}></div>
      </div>
    </>
  );
};
const simpleLineSetup = {
  type: "SimpleLine",
  label: "Linia rozdzielająca",
  preview: process.env.PUBLIC_URL + "/elements/Line.png",
  editable: {
    theme: "light",
    content: "Twoja zawartość paragrafu",
  },
};
const SimpleLineEditPage = () => {
  return (
    <div>
      <SelectTheme />
    </div>
  );
};

export default SimpleLine;
export { simpleLineSetup, SimpleLineEditPage };
