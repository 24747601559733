const appReducerDefaultState = {};

const appReducer = (state = appReducerDefaultState, action) => {
    switch (action.type) {
        case 'SETUP_PROJECTS': {
            return action.projects;
        }
        case 'ADD_PROJECT': {
            return [ {id: action.id, name: action.name, components: action.components}, ...state ]
        }
        case 'REMOVE_PROJECT': {
            return state.filter( (project) => project.id !== action.project_id )
        }
        default:
            return state;
    }
}

export default appReducer;