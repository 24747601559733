import {useDispatch, useSelector} from "react-redux";
import {editComponent} from "../../actions/website";


const useUpdate = () => {
    const dispatch = useDispatch();
    const id = useSelector(state => state.website.selectedComponent);

    return (change) => dispatch(editComponent( id, change ));
}

export default useUpdate;