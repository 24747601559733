import React from "react";
import TextInput from "../formComponents/TextInput";
import SelectTheme from "../formComponents/SelectTheme";

const SimpleParagraph = ({editable, theme}) => {

    let styles;

    switch (editable.theme) {
        case 'light':
            styles = {
                backgroundColor: theme.light_background,
                color: theme.dark_font,
            };
            break;
        case 'color':
            styles = {
                color: theme.light_font,
                backgroundColor: theme.primary,
            };
            break;
        default: {
            styles = {
                backgroundColor: theme.dark_background,
                color: theme.light_font,
            };
        }
    }


    return (
        <>
            <div
                className='simple-paragraph'
                style={styles}
            >
               <p>{editable.content}</p>
            </div>
        </>
    )
}
const simpleParagraphSetup = {
    type: 'SimpleParagraph',
    label: 'Prosty paragraf',
    preview: process.env.PUBLIC_URL + '/elements/Paragraph.png',
    editable: {
        theme: 'light',
        'content': 'Twoja zawartość paragrafu',
    }
}
const SimpleParagraphEditPage = () => {

    return (
        <div>
            <SelectTheme />
            <TextInput editableName='content' label='Kontent'/>
        </div>
    );
}

export default SimpleParagraph;
export {simpleParagraphSetup, SimpleParagraphEditPage}