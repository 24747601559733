import React, {useEffect} from "react";
import useUpdate from "./useUpdate";
import useSaveUpdate from "./useSaveUpdate";
import useComponent from "./useComponent";
import {LinkRounded} from "@material-ui/icons";
import {TextField} from "@material-ui/core";

import editorComponentsList from "../editorComponentsList";

const LinkInput = ({ editableName, label }) => {
    const component = useComponent();
    const update = useUpdate();
    const save = useSaveUpdate();

    const defaults = editorComponentsList[component.type].setup.editable[editableName];

    useEffect(() => {
        if(!component.editable[editableName]) {
            update({ [editableName]: defaults })
        }
    
    }, [component.editable, defaults, editableName, update])

    return (
        <div className='edit-component-text'>
            <div className='edit-component-text__icon'><LinkRounded /></div>
            <div>
                <div className='edit-component-text__input'>
                    <TextField
                        label={label}
                        value={component.editable[editableName] ? component.editable[editableName].text : defaults.text}
                        onChange={ (e) => update({ [editableName]: { text: e.target.value, link: component.editable[editableName].link } })}
                        onBlur={ (e) => save({ [editableName]: { text: e.target.value, link: component.editable[editableName].link } }) }
                    />
                </div>
                <div className='edit-component-text__input'>
                    <TextField
                        label='Link'
                        value={component.editable[editableName] ? component.editable[editableName].link : defaults.link}
                        onChange={(e) => update( { [editableName]: { link: e.target.value, text: component.editable[editableName].text } } )}
                        onBlur={ (e) => save({ [editableName]: { link: e.target.value, text: component.editable[editableName].text } }) }
                    />
                </div>
            </div>
        </div>
    )
}

export default LinkInput;