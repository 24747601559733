import React, {useEffect, useState} from 'react';
import { history } from "../routers/AppRouter";
import {useDispatch, useSelector} from "react-redux";
import { startLogin } from "../actions/auth";

import Navbar from "./Navbar";
import {Button, LinearProgress} from "@material-ui/core";

const LoginPage = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector( state => !!state.auth.uid )

    const [isLoading, setIsLoading] = useState(false);
    const handleLogin = () => {
        setIsLoading(true);
        dispatch(startLogin());
    }

    useEffect(() => {
        if(isLoggedIn) history.push('/dashboard');
        //eslint-disable-next-line
    }, [])

    return (
        <>
            { isLoading && <LinearProgress className='loading-bar' />}
            <Navbar/>
            <section className='image-section'>
                <div className='image-section__login'>
                    <h1>Zaloguj się</h1>
                    <Button
                        className='material-button-big'
                        color='primary'
                        variant='contained'
                        onClick={handleLogin}
                    >
                        Zaloguj z Google
                    </Button>
                </div>
            </section>
        </>
    );
};

export default LoginPage;