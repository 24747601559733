import React, { useState } from "react";
import { history } from "../routers/AppRouter";
import { useDispatch } from "react-redux";

import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core";

import { startRemoveProject } from "../actions/projects";
import logo from "../images/logo.svg";

const EditorNavbar = ({ name, id }) => {

    const dispatch = useDispatch();
    const [dialog, setDialog] = useState(false);

    const handleDeleteModal = () => {
        setDialog(true);
    }
    const handleDelete = () => {
        history.push('/dashboard');
        dispatch(startRemoveProject(id));
    }

    return (
        <nav className='app-nav'>
            <div className='app-nav__wrapper'>
                <img className='app-nav__logo__img' src={logo} alt='logo'/>
                <p className='app-nav__logo__name'>Webclick</p>
            </div>
            <p>Edytujesz: {name}</p>
            <div>
                <Button
                    className='material-button-medium'
                    color="secondary"
                    onClick={handleDeleteModal}
                >
                    Usuń projekt
                </Button>
                <Button
                    className='material-button-medium'
                    color='primary'
                    onClick={() => history.push('/preview')}
                >
                    Podgląd
                </Button>
                <Button
                    variant='outlined'
                    className='material-button-medium'
                    color='primary'
                    onClick={() => history.push('/dashboard')}
                >
                    Wróć do panelu
                </Button>
            </div>
            <Dialog
                open={dialog}
                aria-labelledby="delete-project-title"
                onClose={() => setDialog(false)}
            >
                <DialogTitle id="delete-project-title">Czy chcesz usunąć ten projekt?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Czy chcesz na pewno usunąć cały projekt? Zmiany będą nieodwracalne.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDelete}
                        color={"default"}
                    >
                        Tak
                    </Button>
                    <Button
                        onClick={() => setDialog(false)}
                        color={"primary"}
                        variant={"outlined"}
                    >
                        Nie
                    </Button>
                </DialogActions>
            </Dialog>
        </nav>
    )
}

export default EditorNavbar;