import React from "react";
import TextInput from "../formComponents/TextInput";
import UploadField from "../formComponents/UploadField";
import SelectTheme from "../formComponents/SelectTheme";

import AnchorLink from 'react-anchor-link-smooth-scroll'

const DarkHeader = ({editable, theme}) => {

    let styles, dividerStyles, scrollButtonStyles;

    switch (editable.theme) {
        case 'light':
            styles = {
                backgroundColor: theme.light_background,
                color: theme.dark_font,
            }
            dividerStyles = {
                backgroundColor:theme.light_accent
            }
            scrollButtonStyles = {
                color: theme.dark_font,
                borderColor: theme.dark_font
            }
            break;
        case 'color':
            styles = {
                backgroundColor: theme.primary,
                color: theme.light_font,
            }
            dividerStyles = {
                backgroundColor: theme.light_font
            }
            scrollButtonStyles = {
                color: theme.light_font,
                borderColor: theme.light_font
            }
            break;
        default:
            styles = {
                backgroundColor: theme.dark_background,
                color: theme.light_font,
            }
            dividerStyles = {
                backgroundColor: theme.dark_accent
            }
            scrollButtonStyles = {
                color: theme.light_font,
                borderColor: theme.light_font
            }
    }



    return (
        <div className='header-dark' style={styles}>
            <div
                className='header-dark__container'
            >
                <div className='header-dark__img-wrapper'>
                    <img
                        className='header-dark__img'
                        src={editable.img}
                        alt="background"
                    />
                </div>
                <div className='header-dark__wrapper'>
                    <h1 className='header-dark__title'>{editable.title}</h1>
                    <div className='header-dark__break' style={dividerStyles}></div>
                    <p className='header-dark__description'>{editable.description}</p>
                    <AnchorLink style={scrollButtonStyles} className='header-dark__button'
                                href={`#${editable.title}`}>{editable.button}</AnchorLink>
                </div>
            </div>
            <div id={editable.title}></div>
        </div>
    )
}
const darkHeaderSetup = {
    type: 'DarkHeader',
    label: 'Strona tytułowa',
    preview: process.env.PUBLIC_URL + '/elements/StronaTytulowa.png',
    editable: {
        'theme': 'light',
        'title': 'Twój tytuł',
        'img': process.env.PUBLIC_URL + '/preview/PicturePreview.png',
        'description': 'Twój opis',
        'button': 'Zobacz więcej',
    }
}
const DarkHeaderEditPage = () => {

    return (
        <div>
            <SelectTheme/>
            <TextInput editableName='title' label='Tytuł'/>
            <TextInput editableName='description' label='Opis'/>
            <TextInput editableName='button' label='Tekst przycisku'/>
            <UploadField editableName='img' label='Zdjęcie dekoracyjne'/>
        </div>
    );
}

export default DarkHeader;
export {darkHeaderSetup, DarkHeaderEditPage}