import React from "react";
import selectImage from '../images/editor/select-component.jpg';

const EditorTrayNoElementSelected = () => (
    <div className='editor__tray__select-component'>
        <img src={selectImage} alt='select'/>
        <h2>Nie wybrano elementu</h2>
        <p>Kliknij na komponent w podglądzie po prawej stronie by rozpocząć jego edycję.</p>
    </div>
);

export default EditorTrayNoElementSelected;