import React from 'react';
import ctaImage from "../images/homepage/ctaimage.jpg";
import logo from "../images/logo.svg";
import {Link} from 'react-router-dom';
import {history} from "../routers/AppRouter";

import {Speed, Brush, MoneyOff, ArrowDownwardRounded} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Homepage = () => {

    return (
        <>
            <Navbar>
                <a href='#info'>oferta</a>
                <Link to='/login'>zacznij korzystać</Link>
            </Navbar>
            <section className='image-section'>
                <img src={logo} alt='logo' className='image-section__logo'/>
                <div>
                    <h1 className='image-section__title'>Webclick</h1>
                    <p className='image-section__description'>WEBSITE FOR EVERYONE</p>
                </div>
                <a href="#info"><ArrowDownwardRounded className='image-section__arrow'/></a>
            </section>
            <section className="cta-section" id="info">
                <div className="content-container cta-section__wrapper">
                    <div className="cta-section__content">
                        <h1 className="cta-section__title">Stwórz swoją własną stronę w kilka minut!</h1>
                        <p className="cta-section__text">Promuj się w internecie, publikuj treści i podnieś wiarygodność
                            swojej firmy przy pomocy swojej własnej, spersonalizowanej witryny. Stwórz ją już dziś bez
                            żdanych umiejętności programistycznych!</p>
                        <Button
                            className="material-button-big"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                history.push('/login');
                            }}
                        >
                            Zacznij już dziś!
                        </Button>
                    </div>
                    <img className="cta-section__image" src={ctaImage} alt='cta'/>
                </div>
            </section>
            <section className='content-container features-section'>
                <div className='features-section__title'>
                    <h2>Zalety webclick</h2>
                    <p>Zacznij korzystać z webclick już dziś i przekonaj się jakie to proste!</p>
                </div>
                <div className='features-section__list'>
                    <div className='features-section__item'>
                        <Speed className='features-section__item__icon'/>
                        <h3 className='features-section__item__title'>szybko</h3>
                        <p>Edytor wizualny webclick pozwoli ci zbudować własną responsywną stronę w zaledwie kilka
                            kliknięć</p>
                    </div>
                    <div className='features-section__item'>
                        <Brush className='features-section__item__icon'/>
                        <h3 className='features-section__item__title'>estetycznie</h3>
                        <p>Wprowadź treść i pokaż nam jak chcesz by wyglądała twoja strona. Resztę rozplanujemy za
                            ciebie</p>
                    </div>
                    <div className='features-section__item'>
                        <MoneyOff className='features-section__item__icon'/>
                        <h3 className='features-section__item__title'>tanio</h3>
                        <p>Skorzystaj z hostingu statycznego oraz darmowej subdomeny i prowadź swoją stronę za grosze!</p>
                    </div>
                </div>
            </section>
            <Footer>
                <Link to='/login' className='footer-section__link'>Zaloguj się</Link>
                <Link to='/dashboard' className='footer-section__link'>Panel</Link>
            </Footer>
        </>
    )
};

export default Homepage;