import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import setUsrPreferences from "./../analytics";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserVoteDialog() {
  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState(0);
  const message = useRef("Podoba Ci sie nasza strona?");
  const message2 = useRef('Pomóż głosując na nas w konkursie "Innowator jutra"! https://www.innowatorjutra.pl/');
  const moves = useRef(20);

  const handleClose = () => {
    setOpen(false);
  };

  const handleReject = () => {
    moves.current = 200;
    message.current = "Widzimy, że podoba Ci się nasza strona!";
    message2.current = 'Twój głos to dla nas naprawdę wielka pomoc. Zagłosuj na nas w konkursie "Innowator jutra"! To nic nie kosztuje!';
    setOpen(false);
  };

  const confirm = () => {
    localStorage.setItem("user-voted", true);
    setStage(1);
    setUsrPreferences();
  };

  useEffect(() => {
    if (localStorage.getItem("user-voted") !== 'true') {
      document.addEventListener("click", () => {
        moves.current--;
        if (moves.current === 0) setOpen(true);
      });
    }
    return;
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {stage === 0 ? message.current : "Dziękujemy za głos!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {stage === 0
              ? message2.current
              : "Dziękujemy! Wierzymy, że dzięki naszemu rozwiązaniu internet będzie dla każdego łatwy i intuicyjny!"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {stage === 0 && (
            <Button onClick={confirm} color="primary">
              Głosuję
            </Button>
          )}
          {
            <Button onClick={stage === 0 ? handleReject : handleClose} color="primary">
              Zamknij
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
