import React from "react";
import { ImageRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import useUpload from "./useUpload";
import useSaveUpdate from "./useSaveUpdate";
import useUpdate from "./useUpdate";

const UploadField = ({ editableName, label }) => {
  const upload = useUpload();
  const save = useSaveUpdate();
  const update = useUpdate();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("Wystąpił błąd");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkFile = (file) => {
    const filename = file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);
    if (filename === "jpg" || filename === "jpeg" || filename === "png") return false;
    return true;
  };

  const handleUpload = (e) => {
    const file = e.target.files[0] || { size: 0, name: undefined };

    if (checkFile(file)) {
      setMessage("Wybrano niepoprawny plik");
      handleClickOpen();
    } else if ((file.size / 1024 / 1024).toFixed(4) > 2) {
      setMessage("Wybierz zdjęcie poniżej 2MB");
      handleClickOpen();
    } else if (file.size !== 0 && file.name !== undefined) {
      upload(file).then((link) => {
        save({ [editableName]: link });
        update({ [editableName]: link });
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
      <div className="edit-component-upload">
        <div className="edit-component-upload__icon">
          <ImageRounded />
        </div>
        <div className="edit-component-upload__input">
          <Button variant="outlined" component="label" size="small">
            Wgraj {label}
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              size="50000"
              hidden
              onChange={handleUpload}
            />
          </Button>
        </div>
      </div>
    </>
  );
};

export default UploadField;
