import React from "react";
import TextInput from "../formComponents/TextInput";
import SelectTheme from "../formComponents/SelectTheme";

const SimpleFooter = ({editable, theme}) => {

    let styles;

    switch (editable.theme) {
        case 'light':
            styles = {
                backgroundColor: theme.light_background,
                color: theme.dark_font,
            };
            break;
        case 'color':
            styles = {
                color: theme.light_font,
                backgroundColor: theme.primary,
            };
            break;
        default: {
            styles = {
                backgroundColor: theme.dark_background,
                color: theme.light_font,
            };
        }
    }


    return (
        <>
            <div
                className='simple-footer'
                style={styles}
            >
               <p>{editable.content}</p>
            </div>
        </>
    )
}
const simpleFooterSetup = {
    type: 'SimpleFooter',
    label: 'Prosta stopka',
    preview: process.env.PUBLIC_URL + '/elements/SimpleFooter.png',
    editable: {
        theme: 'dark',
        'content': '©WebClick 2020. All Rights Reserved.',
    }
}
const SimpleFooterEditPage = () => {

    return (
        <div>
            <SelectTheme />
            <TextInput editableName='content' label='Treść stopki'/>
        </div>
    );
}

export default SimpleFooter;
export {simpleFooterSetup, SimpleFooterEditPage}