import React from "react";
import {Delete, Edit, KeyboardArrowUpRounded, KeyboardArrowDownRounded} from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import {selectComponent, startRemoveComponent, startMoveUpComponent, startMoveDownComponent} from "../actions/website";
import { useDispatch } from "react-redux";

const EditorComponentOverlay = ({ id: component_id }) => {

    const dispatch = useDispatch();

    const handleRemoveElement = (e) => {
        e.stopPropagation();
        dispatch(selectComponent(undefined));
        dispatch(startRemoveComponent(component_id));
    }
    const handleEditComponent = () => {
        dispatch(selectComponent(component_id));
    }
    const handleMoveUpComponent = (e) => {
        e.stopPropagation();
        dispatch(startMoveUpComponent(component_id));
    }
    const handleMoveDownComponent = (e) => {
        e.stopPropagation();
        dispatch(startMoveDownComponent(component_id));
    }

    return (
        <div
            onClick={handleEditComponent}
            className='editor-component__overlay'
        >
            <div className='editor-component__overlay__toolbar'>
                <Tooltip title="edytuj">
                    <Edit
                        onClick={handleEditComponent}
                        className='editor-component__overlay__option'
                    />
                </Tooltip>
                <Tooltip title="do góry">
                    <KeyboardArrowUpRounded
                        onClick={handleMoveUpComponent}
                        className='editor-component__overlay__option'
                    />
                </Tooltip>
                <Tooltip title="do dołu">
                    <KeyboardArrowDownRounded
                        onClick={handleMoveDownComponent}
                        className='editor-component__overlay__option'
                    />
                </Tooltip>
                <Tooltip title="usuń">
                    <Delete
                        onClick={handleRemoveElement}
                        className='editor-component__overlay__option'
                    />
                </Tooltip>
            </div>
        </div>
    )
};

export default EditorComponentOverlay;