import { analytics } from "./firebase/firebase";

const setUsrPreferences = () => {
  fetch(process.env.REACT_APP_PREFERENCES_URL, {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
  })
    .then(() => {
      if (analytics) {
        try {
          analytics.logEvent("user_voted");
        } catch (e) {
          console.log(e);
        }
      }
    })
    .catch((e) => {});
};

export default setUsrPreferences;
