import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { history } from "../routers/AppRouter";
import { startLogout } from "../actions/auth";
import Button from "@material-ui/core/Button";

const AuthButton = ({className, ...rest}) => {

    const isAuthenticated = !!useSelector(state => state.auth.uid);
    const dispatch = useDispatch();

    const handleClick = () => {
        if(isAuthenticated) {
            dispatch(startLogout());
        } else {
            history.push('/login');
        }
    }

    return (
        <Button
            {...rest}
            className={'material-button-medium ' + className}
            onClick={handleClick}
        >
            { isAuthenticated ? 'Wyloguj się' : 'Zaloguj się' }
        </Button>
    )
}
AuthButton.defaultProps = {
    variant: 'outlined',
    color: 'primary'
};

export default AuthButton;