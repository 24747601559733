import React from "react";
import WebsitePreview from "./WebsitePreview";
import {useSelector} from "react-redux";
import EditorNavbar from "./EditorNavbar";
import EditorTray from "./EditorTray";

const EditWebsite = () => {

    const website = useSelector(state => state.website);
    const {
        name,
        components,
        id
    } = website;

    return (
        <>
            <EditorNavbar name={name} id={id} />
            <div className='editor'>
                <EditorTray />
                <div className='editor__preview'>
                <WebsitePreview components={components} />
                </div>
            </div>
        </>
    )
}

export default EditWebsite;