import React from 'react';
import {CircularProgress} from "@material-ui/core";
import logo from '../images/logo.svg';

const LoadingPage = () => (
  <section className='image-section'>
      <img src={logo} alt='logo' className='image-section__logo'/>
      <div>
          <h1 className='image-section__title'>Webclick</h1>
          <CircularProgress />
      </div>
  </section>
);

export default LoadingPage;