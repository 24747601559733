import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import projectsReducer from '../reducers/projects';
import websiteReducer from '../reducers/website';
import authReducer from '../reducers/auth';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
    return createStore(
        combineReducers({
            projects: projectsReducer,
            website: websiteReducer,
            auth: authReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );
}

export default configureStore;