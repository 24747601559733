const websiteReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SETUP_WEBSITE': {
            return action.website;
        }
        case 'ADD_COMPONENT': {
            return {
                ...state,
                order: [...state.order, action.component.id],
                components: [...state.components, action.component]
            };
        }
        case 'EDIT_COMPONENT': {
            return {
                ...state,
                components: state.components.map((component) => {
                   if(component.id === action.component_id) {
                       return {
                           ...component,
                           editable: {
                               ...component.editable,
                               ...action.new_data
                           }
                       }
                   }
                   return component;
                })
            };
        }
        case 'REMOVE_COMPONENT': {
            return {
                ...state,
                order: state.order.filter( id =>  id !== action.component_id ),
                components: state.components.filter(({ id }) => id !== action.component_id),
                mode: 0
            }
        }
        case 'MOVE_COMPONENT_UP': {

            let components = [ ...state.components ];

            [components[action.currentIndex-1], components[action.currentIndex]]=[components[action.currentIndex], components[action.currentIndex-1]];

            return {
                ...state,
                order: action.new_order,
                components: components
            }
        }
        case 'MOVE_COMPONENT_DOWN': {

            let components = [ ...state.components ];

            [components[action.currentIndex], components[action.currentIndex+1]]=[components[action.currentIndex+1], components[action.currentIndex]];

            return {
                ...state,
                order: action.new_order,
                components: components
            }
        }
        case 'SELECT_COMPONENT': {
            return {
                ...state,
                selectedComponent: action.component_id,
                mode: 1
            }
        }
        case 'SET_TRAY_MODE': {
            return {
                ...state,
                mode: action.mode
            }
        }
        case 'SET_THEME': {
            return  {
                ...state,
                theme: action.theme
            }
        }
        default:
            return state;
    }
}

export default websiteReducer;