import React from "react";
import { TextField } from "@material-ui/core";
import useComponent from "./useComponent";
import useUpdate from "./useUpdate";
import useSaveUpdate from "./useSaveUpdate";
import {TextFieldsRounded} from "@material-ui/icons";

const TextInput = ({ editableName, label='' }) => {

    const component = useComponent();
    const update = useUpdate();
    const save = useSaveUpdate();


    return (
        <div className='edit-component-text'>
            <div className='edit-component-text__icon'><TextFieldsRounded /></div>
            <div className='edit-component-text__input'>
                <TextField
                    label={label}
                    value={component.editable[editableName]}
                    onChange={(e) => update( { [editableName]: e.target.value } )}
                    onBlur={ (e) => save({ [editableName]: e.target.value }) }
                />
            </div>
        </div>
    )
}

export default TextInput;