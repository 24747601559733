import { SlimMenuEditPage, slimMenuSetup, SlimMenu } from "./editorComponents/SlimMenu";
import DarkHeader, { DarkHeaderEditPage, darkHeaderSetup } from "./editorComponents/DarkHeader";
import SimpleParagraph, {
  SimpleParagraphEditPage,
  simpleParagraphSetup,
} from "./editorComponents/SimpleParagraph";
import SimpleFooter, {
  SimpleFooterEditPage,
  simpleFooterSetup,
} from "./editorComponents/SimpleFooter";
import AboutMe, { AboutMeEditPage, aboutMeSetup } from "./editorComponents/AboutMe";
import AboutMeAlt, { AboutMeAltEditPage, aboutMeAltSetup } from "./editorComponents/AboutMeAlt";
import BackgroundimgHeader, {
  BackgroundimgHeaderEditPage,
  backgroundimgHeaderSetup,
} from "./editorComponents/BackgroudimgHeader";

import ParagraphWithTitle, {
  ParagraphWithTitleEditPage,
  paragraphWithTitleSetup,
} from "./editorComponents/ParagraphWithTitle";

import FooterWithLogo, {
  FooterWithLogoEditPage,
  footerWithLogoSetup,
} from "./editorComponents/FooterWithLogo";
import SimplePhoto, { SimplePhotoEditPage, simplePhotoSetup } from "./editorComponents/SimplePhoto";
import ParalaxPhoto, {
  ParalaxPhotoEditPage,
  paralaxPhotoSetup,
} from "./editorComponents/ParalaxPhoto";
import ImageGalleryInline, {
  ImageGalleryInlineEditPage,
  imageGalleryInlineSetup,
} from "./editorComponents/ImageGalleryInline";
import IconsWithDescription, {
  IconsWithDescriptionEditPage,
  iconsWithDescriptionSetup,
} from "./editorComponents/IconsWithDescription";
import SimpleLine, { SimpleLineEditPage, simpleLineSetup } from "./editorComponents/SimpleLine";
import SocialMedia, { SocialMediaEditPage, socialMediaSetup } from "./editorComponents/SocialMedia";

const editorComponentsList = {
  SlimMenu: {
    component: SlimMenu,
    setup: slimMenuSetup,
    EditPage: SlimMenuEditPage,
  },
  DarkHeader: {
    component: DarkHeader,
    setup: darkHeaderSetup,
    EditPage: DarkHeaderEditPage,
  },
  BackgroundimgHeader: {
    component: BackgroundimgHeader,
    setup: backgroundimgHeaderSetup,
    EditPage: BackgroundimgHeaderEditPage,
  },
  AboutMe: {
    component: AboutMe,
    setup: aboutMeSetup,
    EditPage: AboutMeEditPage,
  },
  AboutMeAlt: {
    component: AboutMeAlt,
    setup: aboutMeAltSetup,
    EditPage: AboutMeAltEditPage,
  },
  ParagraphWithTitle: {
    component: ParagraphWithTitle,
    setup: paragraphWithTitleSetup,
    EditPage: ParagraphWithTitleEditPage,
  },
  SimpleParagraph: {
    component: SimpleParagraph,
    setup: simpleParagraphSetup,
    EditPage: SimpleParagraphEditPage,
  },
  SimpleLine: {
    component: SimpleLine,
    setup: simpleLineSetup,
    EditPage: SimpleLineEditPage,
  },
  SimplePhoto: {
    component: SimplePhoto,
    setup: simplePhotoSetup,
    EditPage: SimplePhotoEditPage,
  },
  ParalaxPhoto: {
    component: ParalaxPhoto,
    setup: paralaxPhotoSetup,
    EditPage: ParalaxPhotoEditPage,
  },
  ImageGalleryInline: {
    component: ImageGalleryInline,
    setup: imageGalleryInlineSetup,
    EditPage: ImageGalleryInlineEditPage,
  },
  SocialMedia: {
    component: SocialMedia,
    setup: socialMediaSetup,
    EditPage: SocialMediaEditPage,
  },
  IconsWithDescription: {
    component: IconsWithDescription,
    setup: iconsWithDescriptionSetup,
    EditPage: IconsWithDescriptionEditPage,
  },
  SimpleFooter: {
    component: SimpleFooter,
    setup: simpleFooterSetup,
    EditPage: SimpleFooterEditPage,
  },
  FooterWithLogo: {
    component: FooterWithLogo,
    setup: footerWithLogoSetup,
    EditPage: FooterWithLogoEditPage,
  },
};

export default editorComponentsList;
