import React from "react";
import TextInput from "../formComponents/TextInput";
import LinkInput from "../formComponents/LinkInput";
import SelectTheme from "../formComponents/SelectTheme";
import { Facebook, MessageRounded, Instagram, LinkedIn, GitHub, Twitter } from "@material-ui/icons";

const SocialMedia = ({ editable, theme }) => {
  let styles;

  switch (editable.theme) {
    case "light":
      styles = {
        backgroundColor: theme.light_background,
        color: theme.dark_font,
      };
      break;
    case "color":
      styles = {
        color: theme.light_font,
        backgroundColor: theme.primary,
      };
      break;
    default: {
      styles = {
        backgroundColor: theme.dark_background,
        color: theme.light_font,
      };
    }
  }

  return (
    <>
      <div className="social-media" style={styles}>
        <div className="social-media__wrapper">
          {editable.title && <h2 className="social-media__title">{editable.title}</h2>}
          <div className="social-media__icons-wrapper">
            {editable.link1.link && (
              <div className="social-media__container">
                <a href={editable.link1.link} className="social-media__icon-link">
                  <Facebook className="social-media__icon" style={styles} />
                </a>
                {editable.link1.text && (
                  <a href={editable.link1.link} className="social-media__desc" style={styles}>
                    {editable.link1.text}
                  </a>
                )}
              </div>
            )}
            {editable.link2.link && (
              <div className="social-media__container">
                <a href={editable.link2.link} className="social-media__icon-link">
                  <MessageRounded className="social-media__icon" style={styles} />
                </a>
                {editable.link2.text && (
                  <a href={editable.link2.link} className="social-media__desc" style={styles}>
                    {editable.link2.text}
                  </a>
                )}
              </div>
            )}
            {editable.link3.link && (
              <div className="social-media__container">
                <a href={editable.link3.link} className="social-media__icon-link">
                  <Instagram className="social-media__icon" style={styles} />
                </a>
                {editable.link3.text && (
                  <a href={editable.link3.link} className="social-media__desc" style={styles}>
                    {editable.link3.text}
                  </a>
                )}
              </div>
            )}
            {editable.link4.link && (
              <div className="social-media__container">
                <a href={editable.link4.link} className="social-media__icon-link">
                  <LinkedIn className="social-media__icon" style={styles} />
                </a>
                {editable.link4.text && (
                  <a href={editable.link4.link} className="social-media__desc" style={styles}>
                    {editable.link4.text}
                  </a>
                )}
              </div>
            )}
            {editable.link5.link && (
              <div className="social-media__container">
                <a href={editable.link5.link} className="social-media__icon-link">
                  <GitHub className="social-media__icon" style={styles} />
                </a>
                {editable.link5.text && (
                  <a href={editable.link5.link} className="social-media__desc" style={styles}>
                    {editable.link5.text}
                  </a>
                )}
              </div>
            )}
            {editable.link6.link && (
              <div className="social-media__container">
                <a href={editable.link6.link} className="social-media__icon-link">
                  <Twitter className="social-media__icon" style={styles} />
                </a>
                {editable.link6.text && (
                  <a href={editable.link6.link} className="social-media__desc" style={styles}>
                    {editable.link6.text}
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const socialMediaSetup = {
  type: "SocialMedia",
  label: "Social Media",
  preview: process.env.PUBLIC_URL + "/elements/SocialMedia.png",
  editable: {
    theme: "light",
    title: "Linki do Social Mediów:",
    link1: { text: "", link: "https://www.facebook.com" },
    link2: { text: "", link: "https://www.messenger.com" },
    link3: { text: "", link: "https://www.instagram.com" },
    link4: { text: "", link: "https://www.linkedin.com" },
    link5: { text: "", link: "https://www.github.com" },
    link6: { text: "", link: "https://www.twitter.com" },
  },
};
const SocialMediaEditPage = () => {
  return (
    <div>
      <SelectTheme />
      <TextInput editableName="title" label="Tytuł" />
      <LinkInput editableName="link1" label="Facebook" />
      <LinkInput editableName="link2" label="Messenger" />
      <LinkInput editableName="link3" label="Instagram" />
      <LinkInput editableName="link4" label="LinkedIn" />
      <LinkInput editableName="link5" label="GitHub" />
      <LinkInput editableName="link6" label="Twitter" />
    </div>
  );
};

export default SocialMedia;
export { socialMediaSetup, SocialMediaEditPage };
