import React from "react";
import logo from '../images/logo.svg';
import {Button} from "@material-ui/core";
import {history} from "../routers/AppRouter";
import Navbar from "./Navbar";

const NotFound = () => (
    <>
        <Navbar/>
        <section className='image-section'>
            <img className='image-section__logo' src={logo} alt='logo'/>
            <div>
                <h1 className='image-section__title'>404 page not found!</h1>
                <Button color="primary" variant="outlined" className='material-button-medium'
                        onClick={() => history.push('/')}>Homepage</Button>
            </div>
        </section>
    </>
);

export default NotFound;