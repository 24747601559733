import React, { useRef } from 'react';
import { Link } from "react-router-dom";

import logo from "../images/logo.svg";
import AuthButton from "./AuthButton";

import { MenuRounded } from "@material-ui/icons";


const Navbar = (props) => {
    const links = useRef();

    const handleToggleMenu = () => {
        if(window.innerWidth < 720) {
            links.current.classList.toggle('active');
        }
    }

    return (
        <nav className='app-nav'>
            <div className='app-nav__wrapper'>
                <Link to='/' className='app-nav__logo'>
                    <img className='app-nav__logo__img' src={logo} alt='logo'/>
                    <p className='app-nav__logo__name'>Webclick</p>
                </Link>
                <div className='app-nav__links' ref={links} onClick={handleToggleMenu}>
                    {props.children}
                </div>
            </div>
            <MenuRounded
                className='mobile-visible app-nav__mobile-expand'
                onClick={handleToggleMenu}
            />
            <AuthButton
                className='desktop-visible'
            />
        </nav>
    );
}

export default Navbar;