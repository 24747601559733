import React, {useEffect} from 'react';
import { Router, Route, Switch, useLocation } from 'react-router-dom';
import { createBrowserHistory } from "history";

import Homepage from "../components/Homepage";
import Dashboard from "../components/Dashboard";
import LoginPage from "../components/LoginPage";
import NotFound from "../components/NotFound";
import ProjectSetup from "../components/ProjectSetup";
import LoadingPage from "../components/LoadingPage";
import EditWebsite from "../components/EditWebsite";

import PrivateRoute from "./PrivateRoute";
import FullWebsite from "../components/FullWebsite";
import UserVoteDialog from '../components/UserVoteDialog';
//import PublicRoute from "./PublicRoute";

const history = createBrowserHistory();

//after redirect scroll to top of the website
const ScrollToTop = () => {
    const pathname = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const AppRouter = () => (
    <Router history={history}>
        <ScrollToTop />
        <UserVoteDialog />
        <>
            <Switch>
                <Route path="/" exact={true} component={Homepage} />
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/preview" exact={true} component={FullWebsite} />
                <PrivateRoute path='/create/:id' component={EditWebsite} />
                <PrivateRoute path="/create" component={ProjectSetup} />
                <Route path="/login" component={LoginPage} />
                <Route path='/loading' component={LoadingPage} />
                <Route path="*" component={NotFound}/>
            </Switch>
        </>
    </Router>
);

export {history, AppRouter as default}