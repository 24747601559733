import React from "react";
import { history } from "../routers/AppRouter";
import editorComponentsList from "./editorComponentsList";
import {useSelector} from "react-redux";
import { Fab, Tooltip } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";

const FullWebsite = () => {

    let components = useSelector((state) => state.website.components);
    const id = useSelector((state) => state.website.id );
    const theme = useSelector((state) => state.website.theme );
    if (!components) components = [];

    return (
        <div className='preview'>
            <Tooltip title="powrót do edycji">
                <Fab
                    color="primary"
                    aria-label="return"
                    className='editor__preview__back'
                    onClick={() => history.push(`/create/${id}`)}
                >
                    <ExitToApp />
                </Fab>
            </Tooltip>
            {
                components.map(({type, id , ...rest}) => {
                    const Component = editorComponentsList[type].component;
                    return <Component key={id} component={editorComponentsList[type].component} theme={theme} {...rest}/>
                })
            }
        </div>
    )
}

export default FullWebsite;