import React from "react";
import { useSelector } from "react-redux";

import {Redirect, Route} from "react-router-dom";

const PrivateRoute = ({component: Component, ...rest}) => {
    const isAuthenticated = !!useSelector(state => state.auth.uid);

    return (
        <Route {...rest} component={(props) => (
            isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect to={'/login'}/>
            )
        )}/>
    )
}

export default PrivateRoute;