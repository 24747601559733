import React from "react";
import UploadField from "../formComponents/UploadField";

const ParalaxPhoto = ({ editable }) => {
  const styles = {
    backgroundImage: `url(${editable.img})`,
  };

  return (
    <>
      <div className="simple-photo__paralax" style={styles}></div>
    </>
  );
};
const paralaxPhotoSetup = {
  type: "ParalaxPhoto",
  label: "Zdjęcie z efektem paralaksy",
  preview: process.env.PUBLIC_URL + '/elements/ParalaxPhoto.png',
  editable: {
    img: process.env.PUBLIC_URL + "/preview/BackgroundImage2.jpg",
  },
};
const ParalaxPhotoEditPage = () => {
  return (
    <div>
      <UploadField editableName="img" label="Zdjęcie" />
    </div>
  );
};

export default ParalaxPhoto;
export { paralaxPhotoSetup, ParalaxPhotoEditPage };
