import database from "../firebase/firebase";

const setupWebsite = (website) => ({
   type: 'SETUP_WEBSITE',
   website
});
const startSetupWebsite = (projectId) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        let project; //ordered components array

        //fetch project -> order components with order array

        //fetch order and push to order array
        return database.ref(`/users/${uid}/projects/${projectId}`).once('value')
            .then( snap => {
                let components = []; //components in order
                project = snap.val();

                //loop over order array and push components in correct order
                if(project.order) {
                    project.order.forEach(id => {
                        components.push({
                            ...project.components[id],
                            id
                        })
                    })
                }
                return dispatch(setupWebsite({ order: [],...project, components, id: snap.key }))
            })
    }
}

const addComponent = (component) => ({
    type: 'ADD_COMPONENT',
    component
});
const startAddComponent = (component) => {
    return (dispatch, getState) => {
        const projectId = getState().website.id;
        const uid = getState().auth.uid;
        const place = getState().website.components.length;

        return database.ref(`/users/${uid}/projects/${projectId}/components`).push(component)
            .then((ref) => database.ref(`/users/${uid}/projects/${projectId}/order/${place}`).set(ref.key).then(() => ref))
            .then((ref) => dispatch(addComponent({ ...component, id: ref.key})));
    }
}

const removeComponent = (component_id) => ({
   type: 'REMOVE_COMPONENT',
   component_id
});
const startRemoveComponent = (component_id) => {
    return (dispatch, getState) => {
        const projectId = getState().website.id;
        const uid = getState().auth.uid;

        const order = getState().website.order.filter((id) => id !== component_id);

        dispatch(removeComponent(component_id));

        return database.ref(`/users/${uid}/projects/${projectId}/components/${component_id}`).set(null)
            .then(() => database.ref(`/users/${uid}/projects/${projectId}/order`).set(order))
            .catch((e) => console.error(e));
    }
}

const moveComponentUp = (component_id, new_order, currentIndex) => ({
    type: 'MOVE_COMPONENT_UP',
    component_id,
    new_order,
    currentIndex
});
const startMoveUpComponent = (component_id) => {
    return (dispatch, getState) => {
        const projectId = getState().website.id;
        const uid = getState().auth.uid;
        let order = getState().website.order;
        let currentIndex = order.findIndex((id) => id === component_id);
        //if component is first cancel event
        if(currentIndex < 1) return undefined;

        //swap positions
        [order[currentIndex-1], order[currentIndex]] = [order[currentIndex], order[currentIndex-1]];

        //dispatch in redux and save new order to firebase
        dispatch(moveComponentUp(component_id, order, currentIndex));
        return database.ref(`/users/${uid}/projects/${projectId}/order`).set(order);

    }
}

const moveComponentDown = (component_id, new_order, currentIndex) => ({
    type: 'MOVE_COMPONENT_DOWN',
    component_id,
    new_order,
    currentIndex
});
const startMoveDownComponent = (component_id) => {
    return (dispatch, getState) => {
        const projectId = getState().website.id;
        const uid = getState().auth.uid;
        let order = getState().website.order;
        let currentIndex = order.findIndex((id) => id === component_id);

        //if component is last cancel event
        if(currentIndex >= order.length-1) return undefined;

        //swap positions
        [order[currentIndex], order[currentIndex+1]] = [order[currentIndex+1], order[currentIndex]];

        //dispatch in redux and save new order to firebase
        dispatch(moveComponentDown(component_id, order, currentIndex));
        return database.ref(`/users/${uid}/projects/${projectId}/order`).set(order);

    }
}

const editComponent = (component_id, new_data) => ({
   type: 'EDIT_COMPONENT',
   new_data,
   component_id
});

const saveEditComponent = (component_id, changes) => {
    return (dispatch , getState) => {
        const uid = getState().auth.uid;
        const projectId = getState().website.id;

        return database.ref(`/users/${uid}/projects/${projectId}/components/${component_id}/editable`)
              .update(changes);
    }
};

const selectComponent = (component_id) => ({
    type: 'SELECT_COMPONENT',
    component_id
});
const setTrayMode = (mode) => ({
    type: 'SET_TRAY_MODE',
    mode
});
const setTheme = (theme) => ({
    type: 'SET_THEME',
    theme
});
const startSetTheme = (theme) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        const projectId = getState().website.id;

        return database.ref(`/users/${uid}/projects/${projectId}/theme`).set(theme)
            .then(() => {
                dispatch(setTheme(theme));
            })
    }
}
export {
    setupWebsite,
    startSetupWebsite,
    startAddComponent,
    startRemoveComponent,
    startMoveUpComponent,
    startMoveDownComponent,
    editComponent,
    saveEditComponent,
    selectComponent,
    setTrayMode,
    startSetTheme
}