const themesList = [
  {
    name: "Powder twilight",
    light_background: "#ECEFEF",
    dark_background: "#313B4B",
    dark_font: "#242424",
    light_font: "#efefef",
    primary: "#88909A",
    light_accent: "#8E806A",
    dark_accent: "#857373",
    overlay: "rgba(105,81,105,0.6)",
  },
  {
    name: "Night pink",
    light_background: "#FBFAFA",
    dark_background: "#2C2242",
    dark_font: "#242424",
    light_font: "#efefef",
    primary: "#D79C9A",
    light_accent: "#9B95A9",
    dark_accent: "#CA3E6B",
    overlay: "rgba(105,81,105,0.6)",
  },
  {
    name: "Dark mode",
    light_background: "#404040",
    dark_background: "#1f1f1f",
    dark_font: "#a6a6a6",
    light_font: "#cfcfcf",
    primary: "#182345",
    light_accent: "#4a62a8",
    dark_accent: "#843da0",
    overlay: "rgba(31, 31, 31, 0.7)",
  },
  {
    name: "Pink strong accent",
    light_background: "#FFFEFD",
    dark_background: "#152127",
    dark_font: "#242424",
    light_font: "#efefef",
    primary: "#EB104B",
    light_accent: "#F8F2E1",
    dark_accent: "#183C49",
    overlay: "rgba(105,81,105,0.6)",
  },
  {
    name: "Ultra violet",
    light_background: "#F7F8F7",
    dark_background: "#743DC7",
    dark_font: "#242424",
    light_font: "#efefef",
    primary: "#B65DD3",
    light_accent: "#CFA7D3",
    dark_accent: "#9599E8",
    overlay: "rgba(105,81,105,0.6)",
  },
  {
    name: "Dark violet-blue",
    light_background: "#B4C1E7",
    dark_background: "#1F1F38",
    dark_font: "#242424",
    light_font: "#efefef",
    primary: "#7057B2",
    light_accent: "#5B69CC",
    dark_accent: "#5C3A75",
    overlay: "rgba(105,81,105,0.6)",
  },
  {
    name: "Pastel soft pink",
    light_background: "#F5F6F5",
    dark_background: "#281F2A",
    dark_font: "#242424",
    light_font: "#efefef",
    primary: "#8A819C",
    light_accent: "#728482",
    dark_accent: "#95465D",
    overlay: "rgb(83,81,105)",
  },
  {
    name: "October forest",
    light_background: "#F6F4F3",
    dark_background: "#333A3A",
    dark_font: "#242424",
    light_font: "#efefef",
    primary: "#AC9D8B",
    light_accent: "#7F7F57",
    dark_accent: "#C13D59",
    overlay: "rgb(81,105,93)",
  },
  {
    name: "Soft modern",
    light_background: "#EEEEEA",
    dark_background: "#2F3F41",
    dark_font: "#242424",
    light_font: "#efefef",
    primary: "#9A9998",
    light_accent: "#CF9044",
    dark_accent: "#B64E3D",
    overlay: "rgb(105,100,81)",
  },
  {
    name: "Fall evening",
    light_background: "#F3EFEB",
    dark_background: "#2E1B22",
    dark_font: "#242424",
    light_font: "#efefef",
    primary: "#97797D",
    light_accent: "#A15B24",
    dark_accent: "#9C3A2C",
    overlay: "rgb(102,82,72)",
  },
  {
    name: "Simple gray&brown",
    light_background: "#F7F5F4",
    dark_background: "#4C3D3B",
    dark_font: "#242424",
    light_font: "#efefef",
    primary: "#A39C9B",
    light_accent: "#948D6B",
    dark_accent: "#7F7876",
    overlay: "rgb(102,82,72)",
  },
  {
    name: "Ocean algae",
    light_background: "#F0F2F2",
    dark_background: "#394C63",
    dark_font: "#242424",
    light_font: "#efefef",
    primary: "#53C08B",
    light_accent: "#909C9B",
    dark_accent: "#61948B",
    overlay: "rgb(77,102,72)",
  },
];

export default themesList;