import React from "react";
import TextInput from "../formComponents/TextInput";
import UploadField from "../formComponents/UploadField";
import LinkInput from "../formComponents/LinkInput";
import { MenuRounded } from "@material-ui/icons";
import SelectTheme from "../formComponents/SelectTheme";

const SlimMenu = ({ editable, theme }) => {

    let styles, linkStyles;

    switch (editable.theme) {
        case 'dark':
            styles = {
                backgroundColor: theme.dark_background,
                color: theme.light_font
            }
            linkStyles = {
                color: theme.light_font
            }
        break;
        case 'color':
            styles = {
                backgroundColor: theme.primary,
                color: theme.light_font
            }
            linkStyles = {
                color: theme.light_font
            }
        break;
        default: {
            styles = {
                backgroundColor: theme.light_background,
                color: theme.dark_font
            }
            linkStyles = {
                color: theme.dark_font
            }
        }
    }

    const expandMenu = () => {
        document.getElementById(`${editable.title}-collapsible`).classList.toggle('active');
    }

    return (
        <nav
            className='menu-slim'
            style={styles}
        >
            <div className='menu-slim__brand' >
                <img className='menu-slim__logo'  src={editable.img} alt="logo"/>
                <p className='menu-slim__title'>{editable.title}</p>
                <MenuRounded className='menu-slim__expand' onClick={expandMenu}/>
            </div>
            <div className='menu-slim__links' id={`${editable.title}-collapsible`} style={styles}>
                { editable.link1.text && <a className='menu-slim__link' href={editable.link1.link} style={linkStyles} title={editable.link1.text}>{editable.link1.text}</a> }
                { editable.link2.text && <a className='menu-slim__link' href={editable.link2.link} style={linkStyles} title={editable.link2.text}>{editable.link2.text}</a> }
                { editable.link3.text && <a className='menu-slim__link' href={editable.link3.link} style={linkStyles} title={editable.link3.text}>{editable.link3.text}</a> }
                { editable.link4.text && <a className='menu-slim__link' href={editable.link4.link} style={linkStyles} title={editable.link4.text}>{editable.link4.text}</a> }
                { editable.link5.text && <a className='menu-slim__link' href={editable.link5.link} style={linkStyles} title={editable.link5.text}>{editable.link5.text}</a> }
            </div>
        </nav>
    )
}

const slimMenuSetup = {
    type: 'SlimMenu',
    preview: process.env.PUBLIC_URL + '/elements/SlimMenu.png',
    label: 'Wąskie menu',
    editable: {
        'theme': 'light',
        'title': 'Twój tytuł',
        'img': process.env.PUBLIC_URL + '/preview/LogoPreview.png',
        'link1': { text: 'Link1', link: '' },
        'link2': { text: 'Link2', link: '' },
        'link3': { text: '', link: '' },
        'link4': { text: '', link: '' },
        'link5': { text: '', link: '' }
    }
}
const SlimMenuEditPage = () => {
    return (
      <div>
          <SelectTheme />
          <TextInput editableName='title' label='Tytuł' />
          <UploadField editableName='img' label='Logo'/>
          <LinkInput editableName='link1' label='Tekst pierwszego linka'/>
          <LinkInput editableName='link2' label='Tekst drugiego linka'/>
          <LinkInput editableName='link3' label='Tekst trzeciego linka'/>
          <LinkInput editableName='link4' label='Tekst czwartego linka'/>
          <LinkInput editableName='link5' label='Tekst piątego linka'/>
      </div>
    );
}

export {
    SlimMenuEditPage,
    slimMenuSetup,
    SlimMenu
}