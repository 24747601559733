import React, {useState} from "react";
import { history } from "../routers/AppRouter";
import {useDispatch} from "react-redux";

import {Button, TextField, LinearProgress} from "@material-ui/core";
import {NavigateNext , Close} from "@material-ui/icons";
import ctaImage from "../images/projectsetup/cta-image.jpg";

import { startAddNewProject } from "../actions/projects";

const ProjectSetup = () => {

    const [error, setError] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleBackButton = () => {
        history.push('/dashboard');
    }

    const handleCreateProject = (e) => {
        e.preventDefault();
        const name = e.target.elements[0].value;
        if(!name) {
            setError('Pole nie może pozostać puste!')
        } else {
            setIsLoading(true);
            dispatch(startAddNewProject({ name })).then((projectId) => history.push(`/create/${projectId}`));
        }
    }

    return (
        <>
            { isLoading && <LinearProgress className='loading-bar' /> }
            <section className='simple-form'>
                <div className='simple-form__back'>
                    <Button
                        className='material-button-big'
                        onClick={handleBackButton}
                        size="small"
                    >
                        <Close />
                    </Button>
                </div>
                <div className='content-container'>
                    <div className='simple-form__intro'>
                        <div className='simple-form__content'>
                            <h1 className='simple-form__title'>Podaj nazwę strony</h1>
                            <p>Właśnie przygotowujemy dla Ciebie edytor, w międzyczasie wybierz nazwę dla swojego projektu i kliknij dalej.</p>
                        </div>
                        <img src={ctaImage} alt="let's create" />
                    </div>
                    <form className='simple-form__form' onSubmit={handleCreateProject}>
                        <TextField
                            id="projectName"
                            label="Nazwa strony"
                            className='simple-form__input'
                        />
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            className='material-button-big'
                        >
                            Dalej <NavigateNext />
                        </Button>
                    </form>
                    {error && <p>{error}</p>}
                </div>
            </section>
        </>
    )
}

export default ProjectSetup;