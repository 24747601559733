import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import {firebase} from "./firebase/firebase";
import {history} from "./routers/AppRouter";

import {login, logout} from "./actions/auth";
import {startSetupProjects} from "./actions/projects";

import LoadingPage from "./components/LoadingPage";

const AuthProvider = (props) => {
    const dispatch = useDispatch();
    const [renderApp, setRenderApp] = useState(false);

    const handleLogin = (user) => {
        if(user) {
            dispatch(login(user.uid));
            dispatch(startSetupProjects()).then(() => {
                setRenderApp(true)
                history.push('/dashboard');
            });
        } else {
            dispatch(logout());
            setRenderApp(true)
            history.push('/');
        }
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged(handleLogin)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        renderApp ? (
            props.children
        ) : <LoadingPage />
    )
}

export default AuthProvider;