import React from 'react';
import {Paper} from "@material-ui/core";
import { Apps } from "@material-ui/icons";

const ProjectCard = ({ title, onClick }) => (
    <Paper elevation={3} className='cards-section__card' onClick={onClick}>
        <Apps className='cards-section__card__icon'/>
        <p className='cards-section__card__description'>{title}</p>
    </Paper>
);
export default ProjectCard;