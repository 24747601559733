import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {startAddComponent} from "../actions/website";
import editorComponentsList from "./editorComponentsList";

import addComponentImage from '../images/editor/add-component.jpg';

const AddComponentForm = () => {
    const dispatch = useDispatch();
    const [selectedComponent, selectComponent] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const handleSelect = (e) => {
        selectComponent(e.currentTarget.getAttribute('component-name'));
        if(!isLoading) {
            setIsLoading(true);
            dispatch(startAddComponent(editorComponentsList[e.currentTarget.getAttribute('component-name')].setup)).then(() => {
                setIsLoading(false);
                window.scrollTo(0,document.getElementById('end').offsetTop);
            });
        }
    }

    return (
        <>
            <div className='editor__tray__title'>
                <img src={addComponentImage} alt='add component' />
                <div>
                    <h3>Dodaj element</h3>
                    <p>Kliknij na obrazek by dodać komponent!</p>
                </div>
            </div>
            <div className='editor__tray__add-component'>
                {
                    Object.keys(editorComponentsList).map((component) => {
                        return (
                            <div
                                key={editorComponentsList[component].setup.type}
                                component-name={editorComponentsList[component].setup.type}
                                className={editorComponentsList[component].setup.type === selectedComponent ? 'editor__tray__add-component__item active' : 'editor__tray__add-component__item'}
                                onClick={handleSelect}
                            >
                                <img src={editorComponentsList[component].setup.preview} alt='component'/>
                                <p>{editorComponentsList[component].setup.label}</p>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default AddComponentForm;