import database from "../firebase/firebase";
import {setupWebsite} from "./website";
import themesList from "../website_themes/themesList";

const setupProjects = (projects) => ({
    type: 'SETUP_PROJECTS',
    projects
});

const startSetupProjects = () => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        return database.ref(`users/${uid}/projectsList`).once('value')
            .then((snapshot) => {
                let projects = [];

                snapshot.forEach((childSnapshot) => {
                    projects.unshift({
                        id: childSnapshot.key,
                        ...childSnapshot.val()
                    });
                });

                return projects;
            })
            .then((projects) => dispatch(setupProjects(projects)));
    }
}

const addNewProject = ({name, id}) => ({
    type: 'ADD_PROJECT',
    name,
    id
});

const startAddNewProject = (projectInitialData = {name: 'unnamed website'}) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        const projectData = {
            components: [],
            order: [],
            theme: themesList[0],
            ...projectInitialData
        }

        return database.ref(`users/${uid}/projects`).push(projectData)
            .then(
                (ref) => {
                    return database.ref(`users/${uid}/projectsList/${ref.key}`).update({ name: projectInitialData.name }).then(() => ref);
                }
            )
            .then(
                (ref) => {
                    dispatch(addNewProject({name: projectInitialData.name, id: ref.key}));
                    dispatch(setupWebsite({...projectData, id: ref.key}));
                    return ref.key;
                }
            )
    }
}

const removeProject = (project_id) => ({
    type: 'REMOVE_PROJECT',
    project_id
});

const startRemoveProject = (project_id) => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;

        return database.ref(`users/${uid}/projects/${project_id}`).set(null)
            .then( () => database.ref(`users/${uid}/projectsList/${project_id}`).set(null) )
            .then( () => dispatch(removeProject(project_id)) );
    }
}

export {
    setupProjects,
    startSetupProjects,
    addNewProject,
    startAddNewProject,
    startRemoveProject
}