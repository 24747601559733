import React from "react";

const Footer = (props) => (
    <footer className='footer-section'>
        <div className='content-container footer-section__wrapper'>
            <p className='footer-section__title'>Webclick</p>
            {props.children}
        </div>
    </footer>
)

export default Footer;