import React from "react";
import UploadField from "../formComponents/UploadField";
import TextInput from "../formComponents/TextInput";
import SelectTheme from "../formComponents/SelectTheme";

const IconsWithDescription = ({editable, theme}) => {
    const img1 = {
        backgroundImage: `url(${editable.img1})`,
    };
    const img2 = {
        backgroundImage: `url(${editable.img2})`,
    };
    const img3 = {
        backgroundImage: `url(${editable.img3})`,
    };

    let styles;

    switch (editable.theme) {
        case 'light':
            styles = {
                backgroundColor: theme.light_background,
                color: theme.dark_font,
            };
            break;
        case 'color':
            styles = {
                color: theme.light_font,
                backgroundColor: theme.primary,
            };
            break;
        default: {
            styles = {
                backgroundColor: theme.dark_background,
                color: theme.light_font,
            };
        }
    }

    return (
        <>
            <div className="icons-with-description" style={styles}>
                <div className="icons-with-description__wrapper">
                    <div className="icons-with-description__icon" style={img1}></div>
                    {editable.title1 && (
                        <div className="icons-with-description__title">{editable.title1}</div>
                    )}
                    {editable.desc1 && <div className="icons-with-description__desc">{editable.desc1}</div>}
                </div>
                <div className="icons-with-description__wrapper">
                    <div className="icons-with-description__icon" style={img2}></div>
                    {editable.title2 && (
                        <div className="icons-with-description__title">{editable.title2}</div>
                    )}
                    {editable.desc2 && <div className="icons-with-description__desc">{editable.desc2}</div>}
                </div>
                <div className="icons-with-description__wrapper">
                    <div className="icons-with-description__icon" style={img3}></div>
                    {editable.title3 && (
                        <div className="icons-with-description__title">{editable.title3}</div>
                    )}
                    {editable.desc3 && <div className="icons-with-description__desc">{editable.desc3}</div>}
                </div>
            </div>
        </>
    );
};
const iconsWithDescriptionSetup = {
    type: "IconsWithDescription",
    label: "Ikony z podpisami",
    preview: process.env.PUBLIC_URL + '/elements/IconsWithDescription.png',
    editable: {
        theme: 'light',
        img1: process.env.PUBLIC_URL + "/preview/Icon1.png",
        img2: process.env.PUBLIC_URL + "/preview/Icon2.png",
        img3: process.env.PUBLIC_URL + "/preview/Icon3.png",
        title1: "Tytuł 1",
        title2: "Tytuł 2",
        title3: "Tytuł 3",
        desc1: "",
        desc2: "",
        desc3: "",
    },
};
const IconsWithDescriptionEditPage = () => {
    return (
        <div>
            <SelectTheme />
            <UploadField editableName="img1" label="Ikona 1"/>
            <TextInput editableName="title1" label="Tytuł 1"/>
            <TextInput editableName="desc1" label="Opis 1"/>
            <UploadField editableName="img2" label="Ikona 2"/>
            <TextInput editableName="title2" label="Tytuł 2"/>
            <TextInput editableName="desc2" label="Opis 2"/>
            <UploadField editableName="img3" label="Ikona 3"/>
            <TextInput editableName="title3" label="Tytuł 3"/>
            <TextInput editableName="desc3" label="Opis 3"/>
        </div>
    );
};

export default IconsWithDescription;
export {iconsWithDescriptionSetup, IconsWithDescriptionEditPage};
