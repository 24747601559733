import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import AppRouter from "./routers/AppRouter";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { Provider } from 'react-redux';
import configureStore from "./store/configureStore";

import AuthProvider from "./AuthProvider";

//load fonts
import "fontsource-roboto";
import "fontsource-roboto-slab";

//import css modules
import "normalize.css";
import "./styles/styles.scss";

//setup redux
const store = configureStore();

//setup theme for material-ui
const theme = createMuiTheme({
    typography: {
        htmlFontSize: 10,
        fontSize: 16
    },
    palette: {
        secondary: {
            light: '#EB411B',
            main: '#F51714',
            dark: '#D4210D'
        }
    }
});

//rendered app
const jsx = (
  <Provider store={store}>
      <AuthProvider>
          <ThemeProvider theme={theme}>
            <AppRouter />
          </ThemeProvider>
      </AuthProvider>
  </Provider>
);


//render loading page when app is not loaded
ReactDOM.render(
  jsx,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
