import React from "react";
import AddComponentForm from "./AddComponentForm";
import {useDispatch, useSelector} from "react-redux";
import EditComponentForm from "./EditComponentForm";
import { BottomNavigation, BottomNavigationAction, Tooltip } from "@material-ui/core";
import {AddRounded, EditRounded, BrushRounded} from "@material-ui/icons";
import {setTrayMode} from "../actions/website";
import EditorTrayNoElementSelected from "./EditorTrayNoElementSelected";
import EditorTrayPerzonalize from "./EditorTrayPersonalize";


const EditorTray = () => {
    const website = useSelector((state) => state.website)
    const dispatch = useDispatch();

    const handleNavbar = (e, newPage) => {
        dispatch(setTrayMode(newPage))
    }

    const routePages = () => {
        switch (website.mode) {
            case 0:
                return (
                    <AddComponentForm />
                )
            case 1: {
                return website.selectedComponent ? <EditComponentForm /> : <EditorTrayNoElementSelected />
            }
            case 2:
                return <EditorTrayPerzonalize />;
            default:
                return <AddComponentForm />;
        }
    }

    return (
        <div className='editor__tray'>
            {routePages()}
            <div className='editor__tray__navigation'>
                <BottomNavigation
                    color="primary"
                    value={website.mode}
                    onChange={handleNavbar}
                >
                    <Tooltip title="Nowy element">
                        <BottomNavigationAction
                            label="Dodaj element"
                            icon={<AddRounded />}
                        />
                    </Tooltip>
                    <Tooltip title="Edytuj element">
                        <BottomNavigationAction
                            label="Edytuj element"
                            icon={<EditRounded />}
                        />
                    </Tooltip>
                    <Tooltip title="Personalizuj stronę">
                        <BottomNavigationAction
                            label="Personalizuj"
                            icon={<BrushRounded />}
                        />
                    </Tooltip>
                </BottomNavigation>
            </div>
        </div>
    )
};

export default EditorTray;