import React from "react";
import TextInput from "../formComponents/TextInput";
import UploadField from "../formComponents/UploadField";
import SelectTheme from "../formComponents/SelectTheme";

const FooterWithLogo = ({editable, theme}) => {

    let styles;

    switch (editable.theme) {
        case 'light':
            styles = {
                backgroundColor: theme.light_background,
                color: theme.dark_font,
            };
            break;
        case 'color':
            styles = {
                backgroundColor: theme.primary,
                color: theme.light_font,
            };
            break;
        default: {
            styles = {
                backgroundColor: theme.dark_background,
                color: theme.light_font,
            };
        }
    }


    const logoStyles = {
        backgroundImage: `url(${editable.img})`,
    };

    return (
        <>
            <div className="footer-with-logo" style={styles}>
                <div className="footer-with-logo__logo" style={logoStyles}></div>
                <p>{editable.content}</p>
            </div>
        </>
    );
};
const footerWithLogoSetup = {
    type: "FooterWithLogo",
    label: "Stopka z logiem",
    preview: process.env.PUBLIC_URL + '/elements/FooterWithLogo.png',
    editable: {
        theme: 'light',
        img: process.env.PUBLIC_URL + '/preview/LogoPreview.png',
        content: "©WebClick 2020. All Rights Reserved.",
    },
};
const FooterWithLogoEditPage = () => {
    return (
        <div>
            <SelectTheme />
            <UploadField editableName="img" label="Logo"/>
            <TextInput editableName="content" label="Treść stopki"/>
        </div>
    );
};

export default FooterWithLogo;
export {footerWithLogoSetup, FooterWithLogoEditPage};
