import { useSelector } from "react-redux";
import { uid } from 'uid';

import { storage } from "../../firebase/firebase";

const useUpload = () => {
    const id = useSelector(state => state.auth.uid);
    const projectId = useSelector( state => state.website.id );

    return (file) => {
        return storage.ref(`/images/${id}/${projectId}/`).child(uid()).put(file).then(snap => snap.ref.getDownloadURL());
    }
}

export default useUpload;