import React from "react";
import TextInput from "../formComponents/TextInput";
import UploadField from "../formComponents/UploadField";
import AnchorLink from 'react-anchor-link-smooth-scroll'


const BackgroundimgHeader = ({editable, theme}) => {

    const styles = {
        backgroundColor: theme.overlay,
        backgroundImage: `url(${editable.img})`,
        color: theme.light_font,
    }
    const dividerStyles = {
        backgroundColor: theme.light_accent
    }
    const scrollButtonStyles = {
      border: `2px solid ${theme.light_font}`,
      color: theme.light_font,
    };

    return (
        <>
         <div
            className='backgroundimg-header'
            style={styles}
         >
            <div className='backgroundimg-header__wrapper'>
                    <h1 className='backgroundimg-header__title'>{editable.title}</h1>
                    <div className='backgroundimg-header__break' style={dividerStyles}></div>
                    <p className='backgroundimg-header__description'>{editable.description}</p>
                    <AnchorLink style={scrollButtonStyles} className='backgroundimg-header__button' href={`#${editable.title}`}>{editable.button}</AnchorLink>
                </div>
        </div>
        <div id={editable.title}></div>
        </>
    )
}

const backgroundimgHeaderSetup = {
    type: 'BackgroundimgHeader',
    preview: process.env.PUBLIC_URL + '/elements/StronaTytulowaObraz.png',
    label: 'Strona tytułowa z obrazem',
    editable: {
        'img': process.env.PUBLIC_URL + '/preview/TextBackground.jpg',
        'title': 'Twój tytuł',
        'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mi diam, fringilla eget orci eu, consequat elementum odio. Fusce eget turpis sed lacus hendrerit imperdiet. Aenean semper nibh sit amet nunc maximus, ac interdum mi porttitor. Morbi a lectus id quam ornare mollis vitae at mauris.',
        'button': 'Zobacz więcej'
    }
}

const BackgroundimgHeaderEditPage = () => {

    return (
        <div>
            <UploadField editableName='img' label='Zdjęcie w tle'/>
            <TextInput editableName='title' label='Tytuł'/>
            <TextInput editableName='description' label='Opis'/>
            <TextInput editableName='button' label='Tekst przycisku'/>
        </div>
    );
}

export default BackgroundimgHeader;
export {backgroundimgHeaderSetup, BackgroundimgHeaderEditPage}