import React from "react";
import editorComponentsList from "./editorComponentsList";
import useComponent from "./formComponents/useComponent";
import editComponentImage from '../images/editor/edit-component.jpg';

const EditComponentForm = () => {

    const component = useComponent();

    const EditPage = editorComponentsList[component.type].EditPage;

    return (
        <>
            <div className='editor__tray__title'>
                <img src={editComponentImage} alt='edit'/>
                <div>
                    <h3>Panel edycji</h3>
                    <p>
                        Edytujesz {editorComponentsList[component.type].setup.label}
                    </p>
                </div>
            </div>
            <div className='editor__tray__controls'>
                <EditPage
                    editable={component.editable}
                />
            </div>
        </>
    )
}

export default  EditComponentForm;