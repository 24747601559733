import React, {useState} from "react";
import { useSelector } from "react-redux";
import EditorComponentOverlay from "./EditorComponentOverlay";

const ComponentPreview = ({ component: Component, id, pos, hide, ...rest }) => {

    const [overlayVisible, setOverlayVisibility] = useState(false);
    const theme = useSelector( state => state.website.theme );

    return (
        <span
            className='editor-component'
            component-id={id}
            onMouseOver={() => setOverlayVisibility(true)}
            onMouseLeave={() => setOverlayVisibility(false)}
        >
            <Component {...rest} theme={theme}/>
            {
                overlayVisible && !hide && <EditorComponentOverlay id={id} />
            }
        </span>
    )
}

export default ComponentPreview;