import React from "react";
import { useDispatch, useSelector } from "react-redux";
import themesList from "../website_themes/themesList";
import { startSetTheme } from "../actions/website";

import personalizeImage from '../images/editor/style-component.jpg';

const EditorTrayPersonalize = () => {

    const dispatch = useDispatch();
    const pickedTheme = useSelector((state) => state.website.theme.name)

    const handleSelectPalette = (theme) => {
        dispatch(startSetTheme(theme));
    }

    return (
        <div>
            <div className='editor__tray__title'>
                <img src={personalizeImage} alt='personalize'/>
                <div>
                    <h3>Spersonalizuj stronę:</h3>
                    <p>Ustaw kolory swojej strony</p>
                </div>
            </div>
            <div className='theme-picker'>
                <h3 className='theme-picker__title'>Twoja paleta:</h3>
                    <div className='theme-picker__container'>
                    {
                        themesList.map((theme) => {
                            return (
                                <div
                                    className={pickedTheme === theme.name ? 'theme-picker__item active' : 'theme-picker__item'}
                                    key={theme.name}
                                    onClick={() => handleSelectPalette(theme)}
                                >
                                    <p className='theme-picker__item__name'>{theme.name}</p>
                                    <div className='theme-picker__item__preview'>
                                        <div className='theme-picker__item__preview__color' style={{backgroundColor: theme.light_accent}}/>
                                        <div className='theme-picker__item__preview__color' style={{backgroundColor: theme.primary}}/>
                                        <div className='theme-picker__item__preview__color' style={{backgroundColor: theme.dark_background}}/>
                                        <div className='theme-picker__item__preview__color' style={{backgroundColor: theme.dark_accent}}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
        </div>
    )
}

export default EditorTrayPersonalize;