import React from "react";
import TextInput from "../formComponents/TextInput";
import SelectTheme from "../formComponents/SelectTheme";

const ParagraphWithTitle = ({editable, theme}) => {


    let styles;

    switch (editable.theme) {
        case 'light':
            styles = {
                backgroundColor: theme.light_background,
                color: theme.dark_font,
            };
            break;
        case 'color':
            styles = {
                color: theme.light_font,
                backgroundColor: theme.primary,
            };
            break;
        default: {
            styles = {
                backgroundColor: theme.dark_background,
                color: theme.light_font,
            };
        }
    }


    return (
        <>
            <div className="paragraph-with-title" style={styles}>
                <h2>{editable.title}</h2>
                <p>{editable.content}</p>
            </div>
        </>
    );
};
const paragraphWithTitleSetup = {
    type: "ParagraphWithTitle",
    label: "Paragraf z tytułem",
    preview: process.env.PUBLIC_URL + '/elements/ParagraphWithTitle.png',
    editable: {
        theme: 'light',
        title: "Twój tytuł",
        content: "Twoja zawartość paragrafu",
    },
};
const ParagraphWithTitleEditPage = () => {
    return (
        <div>
            <SelectTheme />
            <TextInput editableName="title" label="Tytuł"/>
            <TextInput editableName="content" label="Kontent"/>
        </div>
    );
};

export default ParagraphWithTitle;
export {paragraphWithTitleSetup, ParagraphWithTitleEditPage};
