import React from "react";
import noComponents from "../images/editor/no-components.jpg";
import {useSelector} from "react-redux";
import ComponentPreview from "./ComponentPreview";
import editorComponentsList from "./editorComponentsList";

const WebsitePreview = () => {

    let components = useSelector((state) => state.website.components);
    if (!components) components = [];

    return (
        <>
            {
                components.length === 0 ? (
                    <div className='center-components'>
                        <img src={noComponents} alt='no components'/>
                        <h3>Twoja strona jest pusta - dodaj trochę elementów w menu po lewej</h3>
                    </div>
                ) : (
                    components.map(({type, id, ...rest}) => {
                        return <ComponentPreview key={id} id={id}
                                                 component={editorComponentsList[type].component} {...rest}/>
                    })
                )
            }
            <div id="end"></div>
        </>
    )
}

export default WebsitePreview;