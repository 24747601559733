import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { history } from "../routers/AppRouter";

import {
    Button,
    Paper,
    LinearProgress,
    Snackbar,
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {AddRounded} from "@material-ui/icons";

import ProjectCard from "./ProjectCard";
import Footer from "./Footer";
import Navbar from "./Navbar";
import AuthButton from "./AuthButton";

import ctaImage from '../images/dashboard/cta-image.jpg';

import {startSetupWebsite} from "../actions/website";


const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [alertVisible, setAlertVisible] = useState(false);

    const dispatch = useDispatch();

    let projects = useSelector(state => state.projects);
    if(!projects) projects = [];

    //track window width to disable editing on mobile devices
    const setWindowSize = () => {
        setScreenWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', setWindowSize);
        return () => window.removeEventListener('resize', setWindowSize);
    }, [])


    //handle width alert
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertVisible(false);
    };

    const handleAddProject = () => {
        if(screenWidth > 1000) {
            history.push('/create');
        } else {
            setAlertVisible(true);
        }
    }
    const startEditingWebsite = (projectId) => {
        if(screenWidth > 1000) {
            setIsLoading(true);
            dispatch(startSetupWebsite(projectId)).then(() => history.push(`/create/${projectId}`));
        } else {
            setAlertVisible(true)
        }
    }

    return (
        <>
            { isLoading && <LinearProgress className='loading-bar' /> }
            <Navbar><AuthButton className='mobile-visible' /></Navbar>
            <section className='cta-section cta-section--on-top'>
                <div className='cta-section__wrapper content-container'>
                    <div className='cta-section__content'>
                        <h1 className='cta-section__title'>Panel użytkownika</h1>
                        <p className='cta-section__text'>Witaj w panelu użytkownika! Stąd możesz zarządzać swoimi stronami.
                            Przeglądaj i edytuj witryny, a także twórz nowe.</p>
                        <Button
                            variant='contained'
                            color='primary'
                            className='material-button-big'
                            onClick={handleAddProject}
                        >
                            Stwórz nową stronę
                        </Button>
                    </div>
                    <img src={ctaImage} alt='cta'/>
                </div>
            </section>
            <section className='content-container cards-section'>
                <div className='cards-section__title'>
                    <h2>Twoje projekty</h2>
                    <p>Lista twoich projektów. Edytuj poprzednią stronę lub stwórz nową!</p>
                </div>
                <Paper elevation={3} className='cards-section__card' onClick={handleAddProject}>
                    <AddRounded className='cards-section__card__icon'/>
                    <p className='cards-section__card__description'>Stwórz nową stronę!</p>
                </Paper>
                {
                    !!projects ?
                    //loop over projects and display them
                    projects.map((project) => {
                        return (
                            <ProjectCard
                                key={project.id}
                                title={project.name}
                                onClick={() => startEditingWebsite(project.id)}
                            />
                        )
                    }) : undefined
                }
            </section>
            <Footer/>
            <Snackbar open={alertVisible} autoHideDuration={4000} onClose={handleAlertClose}>
                <MuiAlert
                    onClose={handleAlertClose}
                    severity="error"
                    variant="filled"
                    elevation={6}
                >
                    By włączyć edytor szerokość twojego ekranu musi mieć minimum 1000px!
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default Dashboard;