import {useDispatch, useSelector} from "react-redux";
import {saveEditComponent} from "../../actions/website";


const useSaveUpdate = () => {
    const dispatch = useDispatch();
    const id = useSelector(state => state.website.selectedComponent)

    return (change) => dispatch(saveEditComponent(id, change));
}

export default useSaveUpdate;