import React from "react";
import UploadField from "../formComponents/UploadField";

const ImageGalleryInline = ({ editable, theme }) => {
  const img1 = {
    backgroundImage: `url(${editable.img1})`,
  };
  const img2 = {
    backgroundImage: `url(${editable.img2})`,
  };
  const img3 = {
    backgroundImage: `url(${editable.img3})`,
  };
  const styles = {
    backgroundColor: theme.dark_background,
  };

  return (
    <>
      <div className="image-gallery-inline" style={styles}>
        <div className="image-gallery-inline__photo" style={img1}></div>
        <div className="image-gallery-inline__photo" style={img2}></div>
        <div className="image-gallery-inline__photo" style={img3}></div>
      </div>
    </>
  );
};
const imageGalleryInlineSetup = {
  type: "ImageGalleryInline",
  label: "Galeria zdjęć w linii",
  preview: process.env.PUBLIC_URL + '/elements/ImageGalleryInline.png',
  editable: {
    img1: process.env.PUBLIC_URL + "/preview/BackgroundImage.jpg",
    img2: process.env.PUBLIC_URL + "/preview/BackgroundImage1.jpg",
    img3: process.env.PUBLIC_URL + "/preview/BackgroundImage2.jpg",
  },
};
const ImageGalleryInlineEditPage = () => {
  return (
    <div>
      <UploadField editableName="img1" label="Zdjęcie 1" />
      <UploadField editableName="img2" label="Zdjęcie 2" />
      <UploadField editableName="img3" label="Zdjęcie 3" />
    </div>
  );
};

export default ImageGalleryInline;
export { imageGalleryInlineSetup, ImageGalleryInlineEditPage };
