import React, {useEffect} from "react";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import useComponent from "./useComponent";
import useUpdate from "./useUpdate";
import useSaveUpdate from "./useSaveUpdate";
import { BrushRounded } from "@material-ui/icons";

const SelectTheme = () => {

    const component = useComponent();
    const update = useUpdate();
    const save = useSaveUpdate();

    useEffect(() => {
        if(!component.editable.theme) {
            component.editable.theme = 'light'
        }
    }, [component.editable])
    
    return (
        <div className='edit-component-text'>
            <div className='edit-component-text__icon'><BrushRounded /></div>
            <div className='edit-component-text__input'>
                <InputLabel id="theme-select">Wybierz kolor</InputLabel>
                <Select
                    value={component.editable.theme}
                    onChange={(e) => {
                        update( { theme: e.target.value } );
                        save({ theme: e.target.value })
                    }}
                >
                    <MenuItem value='light'>Jasny</MenuItem>
                    <MenuItem value='color'>Kolor</MenuItem>
                    <MenuItem value='dark'>Ciemny</MenuItem>
                </Select>
            </div>
        </div>
    )
}

export default SelectTheme;