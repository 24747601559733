import React from "react";
import UploadField from "../formComponents/UploadField";

const SimplePhoto = ({ editable }) => {
  const styles = {
    backgroundImage: `url(${editable.img})`,
  };

  return (
    <>
      <div className="simple-photo" style={styles}></div>
    </>
  );
};
const simplePhotoSetup = {
  type: "SimplePhoto",
  label: "Rozciągnięte zdjęcie",
  preview: process.env.PUBLIC_URL + '/elements/SimplePhoto.png',
  editable: {
    img: process.env.PUBLIC_URL + "/preview/BackgroundImage.jpg",
  },
};
const SimplePhotoEditPage = () => {
  return (
    <div>
      <UploadField editableName="img" label="Zdjęcie" />
    </div>
  );
};

export default SimplePhoto;
export { simplePhotoSetup, SimplePhotoEditPage };
